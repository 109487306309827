@import "~@/styles/variables/variables.scss";
























































.card {
	height: 268px;
}
.box-bar-chart {
	height: 180px;
	position: absolute;
	bottom: 24px;
	right: 16px;
	left: 128px;
	transform-origin: right;
}
::v-deep {
	.gc-blank-page {
		margin-top: 0;
	}
}

@media screen and (max-width: 1860px) {
	.card {
		width: 100%;
		.box-bar-chart {
			left: 12px;
			right: 12px;
			width: auto;
		}
	}
	.card {
		.box-number {
			position: absolute;
			left: inherit !important;
			bottom: inherit !important;
			top: 12px;
			right: 2px;
		}
	}
}
