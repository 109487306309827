@import "~@/styles/variables/variables.scss";











































































































































































::v-deep .card {
	padding: 24px;
	background: #f2f4fc;
	border-radius: 16px;
	position: relative;
	&-title {
		font-size: 20px;
		font-weight: 500;
		color: #333;
		letter-spacing: 1px;
	}
	.box-number {
		position: absolute;
		left: 24px;
		bottom: 24px;
		.text {
			font-size: 12px;
			color: #999;
		}
		.data {
			margin-top: 10px;
			.unit {
				font-size: 16px;
				font-weight: normal;
				color: #333;
				line-height: 24px;
			}
		}
	}
	.bold {
		font-size: 20px;
		font-family: Impact;
		color: #4d4d4d;
		line-height: 24px;
		letter-spacing: 1px;
	}
}
.home {
	height: 100%;
	padding: 20px 24px;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;

	&-top {
		flex: 0 0 515px;
	}

	&-bottom {
		flex: 1;
		min-height: 300px;
		.el-col {
			height: 100%;
			overflow: hidden;
		}
	}
}
.space-21 {
	margin-top: 21px;
}

@media screen and (max-width: 1860px) {
	::v-deep .card {
		padding: 16px;
		&-title {
			font-size: 17px;
			font-weight: 600;
			color: #333;
			letter-spacing: 1px;
		}
	}
}
