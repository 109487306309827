@import "~@/styles/variables/variables.scss";






























.wrapper-item {
	display: flex;
	flex-wrap: wrap;
	.item {
		margin: 15px 0;
		width: 200px;
		.label {
			color: #9ca0b2;
		}
		.data {
			margin-top: 9px;
		}
	}
}
