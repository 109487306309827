@import "~@/styles/variables/variables.scss";














































































































































































.box-user.card {
	height: 226px;
	background: linear-gradient(225deg, #a7a6ff 0%, #a6beff 100%);
	color: #fff;
	position: relative;
	&::after {
		content: '';
		width: 92px;
		height: 44px;
		position: absolute;
		top: 0;
		right: -1px;
		background: url('~@/assets/imgs/icon/home-yellow.png') no-repeat;
		background-size: cover;
		background-position: center;
		border-top-right-radius: 16px;
	}
	.icon {
		position: absolute;
		&.red {
			width: 36px;
			top: 24px;
			right: calc(12% + 128px);
		}
		&.blue {
			width: 36px;
			top: 47px;
			right: 5%;
			z-index: 999;
		}
		&.admin {
			width: 120px;
			bottom: 89px;
			right: 12%;
		}
	}
	.wrapper-hello {
		height: 110px;
	}
	.date {
		font-size: 22px;
		font-weight: 500;
		min-height: 22px;
	}
	.hello {
		margin-top: 16px;
		line-height: 21px;
		width: calc(88% - 161px);
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
	.items {
		height: 89px;
		display: flex;
		align-items: center;
		border-top: 1px dashed #fff;
		.item {
			display: flex;
			align-items: center;
			cursor: pointer;
			+ .item {
				margin-left: 20px;
			}
			.iconfont {
				font-size: 36px;
				&.img {
					width: 36px;
				}
			}
			.item-text {
				margin-left: 12px;
				h3 {
					font-weight: 400;
					letter-spacing: 1px;
				}
				p {
					margin-top: 7px;
					font-size: 12px;
					transform: scale(0.8);
					transform-origin: left;
					white-space: nowrap;
				}
			}
		}
	}
}
