@import "~@/styles/variables/variables.scss";


























































































































.line-chart {
	width: 100%;
	height: 100%;
}
