@import "~@/styles/variables/variables.scss";














































































.card {
	height: 515px;
}
.items {
	padding-top: 20px;
	.item {
		margin-bottom: 16px;
		display: flex;
		align-items: center;
		cursor: pointer;
		&:last-child {
			margin-bottom: 0;
		}
		.icon {
			flex: 0 0 40px;
			height: 40px;
			border-radius: 5px;
		}
		.title {
			flex: 1;
			margin-left: 26px;
			font-size: 16px;
			font-weight: 400;
			color: #333;
			white-space: nowrap;
		}
		.bold {
			flex: 0 0 46px;
			text-align: right;
		}
	}
}
