@import "~@/styles/variables/variables.scss";

































































































.card {
	height: 268px;
	display: flex;
}
.items {
	flex: 1;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.item {
		width: 31%;
		height: 100px;
		background: linear-gradient(225deg, #a7a6ff 0%, #a6beff 100%);
		border-radius: 8px;
		padding: 12px;
		position: relative;
		&:nth-of-type(n + 4) {
			margin-top: 20px;
		}
		.icon {
			width: 28px;
			height: 28px;
		}
		.title {
			margin-top: 8px;
			font-size: 13px;
			color: #fff;
			white-space: nowrap;
		}
		.bold {
			margin-top: 4px;
			color: #fff;
			.unit {
				font-size: 14px;
				font-weight: normal;
			}
		}
		@media (max-width: 1680px) {
			.icon {
				width: 28px;
				height: 28px;
			}
			.title {
				margin-top: 8px;
			}
			.bold {
				margin-top: 8px;
				position: initial;
			}
		}
	}
}
