@import "~@/styles/variables/variables.scss";






























































































































.el-button {
	padding: 0 24px;
	height: 32px;
	border-radius: 16px;
}
.row {
	height: 220px;
	border-bottom: 1px dashed #ccc;
	display: flex;
	align-items: center;
	.box-chart {
		flex: 0 0 200px;
		height: 150px;
		position: relative;
		.title {
			position: absolute;
			left: 50%;
			top: 40%;
			transform: translateX(-50%);
			color: #9ca0b3;
		}
	}
	.box-data {
		flex: 1;
		margin-left: 40px;
	}
}
