@import "~@/styles/variables/variables.scss";








































































.card {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.box-chart {
	margin-top: 20px;
	width: 100%;
	// height: 180px;
	flex: 1;
}
.box-total {
	margin-top: 10px;
	display: flex;
	.item {
		flex: 1;
		text-align: center;
		&-label {
			color: #9ca0b3;
		}
		&-data {
			margin-top: 12px;
		}
	}
}
::v-deep {
	.gc-blank-page {
		margin-top: 0;
		padding-top: 20px;
		.no-data-img {
			width: 133px;
			height: 120px;
		}
	}
}
