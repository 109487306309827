@import "~@/styles/variables/variables.scss";






































































.card {
	height: 100%;
	overflow: auto;
	position: relative;
}
.items {
	padding-top: 16px;
	.item {
		margin-top: 16px;
		display: flex;
		&:last-of-type .content {
			border-bottom: none;
			padding-bottom: 0;
		}
		.dot {
			flex: 0 0 12px;
			height: 12px;
			background: #a1aad8;
			border-radius: 6px;
			margin-top: 6px;
		}
		.content {
			flex: 1;
			margin-left: 12px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 18px;
			border-bottom: 1px dashed #c3c9e6;
			.left {
				flex: 1;
				.split {
					margin-left: 10px;
					margin-right: 10px;
				}
			}
			.right {
				flex: 0 0 180px;
				text-align: right;
				.iconfont {
					margin-left: 20px;
				}
			}
		}
	}
}
::v-deep {
	.gc-blank-page {
		margin-top: 20px;
		.no-data-img {
			width: 133px;
			height: 120px;
		}
	}
}
