@import "~@/styles/variables/variables.scss";














































































.card {
	height: 226px;
	position: relative;
}
.subtitle {
	font-size: 12px;
	color: #999;
	margin-top: 12px;
}
.box-rate-chart {
	width: 176px;
	height: 176px;
	position: absolute;
	top: 25px;
	right: 8%;
	transform-origin: right;
	.icon {
		position: absolute;
		top: 42%;
		left: 50%;
		transform: translate(-50%, -50%);

		.img-rate {
			width: 37px;
		}
	}
}
